import React from "react";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import SEO from "@src/components/SEO";

const Communities = () => {
  useRedirectHandler(() => {
    return `https://home.classdojo.com/#/get-app`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};

export default Communities;
